/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react'
import { Helmet } from 'react-helmet'
import { Route, Switch } from 'react-router-dom'
import HomePage from 'containers/HomePage/Loadable'
import FeaturePage from 'containers/FeaturePage/Loadable'
import ContactPage from 'containers/ContactPage/Loadable'
import TermsPage from 'containers/TermsPage/Loadable'
import PrivacyPage from 'containers/PrivacyPage/Loadable'
import NotFoundPage from 'containers/NotFoundPage/Loadable'
import NewTrialRegistrationPage from 'containers/NewTrialRegistrationPage/Loadable'
import routes from 'utils/routes'
import SystemPage from '../SystemPage/Loadable'
import CaseStudyPage from '../CaseStudyPage/Loadable'
import productStore from 'stores/productStore'
import productThemeStore from 'stores/productThemeStore'
import sourceStore from 'stores/sourceStore'
import WebinarPage from 'containers/WebinarPage/Loadable'
import WebinarsPage from 'containers/WebinarsPage/Loadable'

export default class App extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props)
    this.findProductAndProductThemeAndSource()
  }

  async findProductAndProductThemeAndSource() {
    const domain = window.location.hostname
    await productStore.find(domain)
    const product = productStore.object
    sourceStore.recognize()
    await productThemeStore.find(product.product_theme_id)
  }

  render() {
    return (
      <div>
        <Helmet
          titleTemplate='%s - AndonCloud - OEE, MUR, Lean, MES software.'
          defaultTitle='AndonCloud - OEE, MUR, Lean, MES software.'
        />
        <Switch>
          <Route exact path={routes.home} component={HomePage} />
          <Route path={routes.features} component={FeaturePage} />
          <Route path={routes.system} component={SystemPage} />
          <Route path={routes.contact} component={ContactPage} />
          <Route path={routes.terms} component={TermsPage} />
          <Route path={routes.privacy} component={PrivacyPage} />
          <Route path={routes.newTrialRegistration} component={NewTrialRegistrationPage} />
          <Route path={routes.casestudies} component={CaseStudyPage} />
          <Route path={routes.webinar} component={WebinarPage} />
          <Route path={routes.webinars} component={WebinarsPage} />
          <Route path='' component={NotFoundPage} />
        </Switch>
      </div>
    )
  }
}
