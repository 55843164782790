import { action, observable, toJS } from 'mobx'
import merge from 'lodash/merge'

class AbstractCRUDStore {
  @observable.deep object = {}
  @observable isNewObject = true
  @observable isPersisted = false
  @observable objectLoading = false
  @observable collection = []
  @observable collectionLoading = false

  constructor () {
    this.buildObject()
  }

  @action
  setObject (object) {
    console.log('setObject', object)
    console.log('before', toJS(this.object), 'this.isNewObject', this.isNewObject)
    const objectJS = toJS(this.object)
    object = merge(objectJS, object)
    this.object = object
    console.log('setObject after', toJS(this.object))
  }

  @action
  setObjectValue (key, value) {
    const object = {}
    object[key] = value
    this.setObject(object)
  }

  @action
  find (id) {
    const f = () => {
      return this.crud.find(id)
    }
    return this.perform(f, 'object')
  }

  @action
  create () {
    let object = toJS(this.object)
    object = this.transformRequestData(object)
    const f = () => {
      return this.crud.create(object)
    }
    return this.perform(f, 'object')
  }

  @action
  update () {
    let object = toJS(this.object)
    object = this.transformRequestData(object)
    const f = () => {
      return this.crud.update(object)
    }
    return this.perform(f, 'object')
  }

  @action
  updateOrCreate () {
    let object = toJS(this.object)
    object = this.transformRequestData(object)
    const f = () => {
      return this.crud.updateOrCreate(object)
    }
    return this.perform(f, 'object')
  }

  @action
  all () {
    const f = () => {
      return this.crud.all()
    }
    return this.perform(f, 'collection')
  }

  @action
  destroy (id) {
    const f = () => {
      return this.crud.destroy(id)
    }
    return this.perform(f, 'object')
  }

  async perform (f, resource) {
    this.guard()

    if (resource === 'object') {
      this.objectLoading = true
    } else {
      this.collectionLoading = true
    }

    let response = null
    try {
      response = await f()
    }
    finally {
      if (resource === 'object') {
        this.objectLoading = false
      } else {
        this.collectionLoading = false
      }
    }

    if (response.status !== 200 && response.status !== 201) {
      console.log('response.status !== 200 && response.status !== 201')
      this.buildObject()
      return response
    }

    if (response.status === 400 && response.status === 500) {
      return response
    }

    let { data } = response
    data = toJS(data)
    data = this.transformResponseData(data)
    if (resource === 'object') {
      console.log("resource === 'object'")
      this.object = data
      this.isNewObject = false
      this.isPersisted = true
      this.objectLoading = false
    } else if (resource === 'collection') {
      this.collection = data
      this.collectionLoading = false
    }
    console.log('object after perform:', this.object)
    return response
  }

  transformRequestData (data) {
    console.log('transformRequestData:', data)
    return data
  }

  transformResponseData (data) {
    console.log('transformResponseData:', data)
    return data
  }

  buildObject () {
    this.object = this.emptyObject()
    this.isPersisted = false
    this.isNewObject = true
  }

  emptyObject () {
    return {}
  }

  guard () {
    this.checkCRUDPresence()
  }

  setCRUD (crud) {
    this.crud = crud
  }

  checkCRUDPresence () {
    if (!this.crud) {
      throw new Error('Please set CRUD before use CRUD store!')
    }
  }
}

export default AbstractCRUDStore
