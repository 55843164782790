import { createGlobalStyle } from 'styled-components'

/* eslint no-unused-expressions: 0 */
export default createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body, * {
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    letter-spacing: .05em;
  }

  #app {
    background-color: #fff;
    min-height: 100%;
    min-width: 100%;
  }

  p, label {
    line-height: 1.5em;
  }
`
