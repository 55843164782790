import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga'
import { BrowserRouter } from 'react-router-dom'
import App from './containers/App';
import * as serviceWorker from './serviceWorker'
import 'index.css'


import { createBrowserHistory } from 'history'

import FontFaceObserver from 'fontfaceobserver'
import 'sanitize.css/sanitize.css'
import 'antd/dist/antd.css'


// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider'

import { ThemeProvider } from 'styled-components'
import theme from './theme'


// Import i18n messages
import { translationMessages } from '../src/i18n'

// Import CSS reset and Global Styles
import GlobalStyle from './globalStyles'

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const latoObserver = new FontFaceObserver('Lato', {})

// When Open Sans is loaded, add a font-family using Open Sans to the body
latoObserver.load().then(() => {
  document.body.classList.add('fontLoaded')
}, () => {
  document.body.classList.remove('fontLoaded')
})

const history = createBrowserHistory()
const MOUNT_NODE = document.getElementById('root')

// TODO: this function was not called even during a short manual test
function addRouteChangeListener() {
  history.listen((location) => {
    console.log('addRouteChangeListener')
    window.scrollTo(0, 0)
    // eslint-disable-next-line no-restricted-globals
    ReactGA.pageview(window.location.pathname + location.search)
  })
}

function addGoogleAnalytics() {
  console.log('addGoogleAnalytics')
  ReactGA.initialize('UA-81032143-1', { debug: true })
  // eslint-disable-next-line no-restricted-globals
  ReactGA.pageview(window.location.pathname + location.search)
}

const render = (messages) => {
  addRouteChangeListener()
  addGoogleAnalytics()
  ReactDOM.render(
    <LanguageProvider messages={messages}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </LanguageProvider>,
    MOUNT_NODE
  )
}

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE)
    render(translationMessages)
  })
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  (new Promise((resolve) => {
    resolve(import('intl'))
  }))
    .then(() => Promise.all([
      import('intl/locale-data/jsonp/pl.js'),
      import('intl/locale-data/jsonp/en.js')
    ]))
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err
    })
} else {
  render(translationMessages)
}



// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
