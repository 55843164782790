const routes = {
  'home': '/',
  'features': '/features',
  'system': '/system',
  'casestudies': '/case-studies/:id',
  'resources': '/resources',
  'contact': '/contact',
  'terms': '/terms',
  'privacy': '/privacy',

  'slides': '/slides',
  'newTrialRegistration': '/trial_registrations/new',
  
  'guides': '/guides',
  'guide': '/guides/:id',

  'webinars': '/webinars',
  'webinar': '/webinars/:id'
}

export default routes
