import axios from 'axios'

class AbstractCRUD {
  async create (requestData) {
    const method = 'POST'
    this.guard(method)
    const url = `${this.resourceUrl}/`
    const response = await this.perform(url, method, requestData)
    return response
  }

  async find (id) {
    const method = 'GET'
    this.guard(method)
    const url = `${this.resourceUrl}/${id}`
    const response = await this.perform(url, method, {})
    return response
  }

  async all () {
    const method = 'GET'
    this.guard(method)
    const url = `${this.resourceUrl}/`
    const response = await this.perform(url, method, {})
    return response
  }

  async update (requestData) {
    const method = 'PATCH'
    this.guard(method)
    const url = `${this.resourceUrl}/${requestData.id}`
    const response = await this.perform(url, method, requestData)
    return response
  }

  async updateOrCreate (requestData) {
    if (requestData.id) {
      return this.update(requestData)
    } else {
      return this.create(requestData)
    }
  }

  async destroy (id) {
    const method = 'DELETE'
    this.guard(method)
    const url = `${this.resourceUrl}/${id}`
    const response = await this.perform(url, method, {})
    return response
  }

  perform (url, method, data) {
    let object = {}
    object[this.rootKey] = data
    const response = axios({
      method: method,
      url: url,
      data: object
    })
    return response
  }

  guard (method) {
    this.checkApiUrlPresence()
    this.checkResourcePresence()
    this.checkAllowedMethods()
    this.checkAllowedMethod(method)
  }

  setRootKey (rootKey) {
    this.rootKey = rootKey
  }

  setResourcePath (resourcePath) {
    this.resourcePath = resourcePath
    this.resourceUrl = this.apiUrl + this.resourcePath
  }

  checkResourcePresence () {
    if (!this.resourcePath) {
      throw new Error('Please set resourcePath before use CRUD resource!')
    }
  }

  setApiUrl (apiUrl) {
    this.apiUrl = apiUrl
  }

  checkApiUrlPresence () {
    if (!this.apiUrl) {
      throw new Error('Please set apiUrl before use CRUD resource!')
    }
  }

  setAllowedMethods (methods) {
    this.methods = methods
  }

  checkAllowedMethods () {
    if (!this.methods) {
      throw new Error("Please set methods ('POST', 'PATCH', 'GET', 'DESTROY') before use CRUD resource!")
    }
  }

  checkAllowedMethod (method) {
    if (!this.methods.includes(method)) {
      throw new Error(`Method ${method} is disallow`)
    }
  }
}

export default AbstractCRUD
